h1, h2, h3 {
  color: #2b2b2b;
  font-family: 'Canela Web', Times, serif;
  font-weight: 500;
}

* {
  font-family: 'Roboto Mono', Courier, Helvetica, Arial, sans-serif;
  color: #2B2B2B;
  line-height: 1.5;
}

// typography

h1 {
  font-size: 4.2rem;
  line-height: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  br {line-height: 1rem}
}

h2 {
  font-size: 3rem;
  margin: 2rem 0;
}

h3, .my-stats h2 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  position: relative;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  color: $black;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 1.8rem;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  line-height: 2;
}

.text-link {
  display: inline-block;
}


a {
  text-decoration: none;
  // position: relative;
  color: white;
  transition: all 0.2s ease;
  &:hover {
    color: #E4EBF4;
  }
}

.font-light {
  font-weight: $font-weight-light;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}

.center {
  text-align: center;
}

// Custom

.site {
  *, h1, h2, h3, {
    color: white;
  }
  p {
    color: #CCCCCC;
  }
}

.web {
  color: #A0BE8D;
}

.quote {
  max-width: 28.125rem;
  padding-right: 1rem;
}
