.tooltip {
  // background-color: #ffaffc;
  // border-radius: 10px;
  // padding: 10px 15px;
  position: relative;
  // margin: 15px;
  text-align: center;
}

.tooltip::after {
  background-color: #2b2b2b;
  border-radius: 5px;
  color: white;
  display: none;
  font-size: 0.8rem;
  padding: 8px 12px;
  position: absolute;
  text-align: center;
  z-index: 999;

}

.tooltip::before {
  background-color: #333;
  content: ' ';
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.tooltip:hover::after {
  display: block;
}

.tooltip:hover::before {
  display: block;
}

.tooltip.top::after {
  content: 'You happy now Cuz? 😆';
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip.bottom::after {
  content: 'bottom';
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.tooltip.bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.tooltip.right::after {
  content: 'right';
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.tooltip.right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.tooltip.left::after {
  content: 'left';
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.tooltip.left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}
