img {
  max-width: 100%;
  height: auto;
  // image-rendering: auto;
}

.img-full { width: 100%; margin: 48px 0; }
.img-blob { margin-top: 32px; }
.img-r { float: right }
.img-l { float: left }

.surf-illustration {
  position: relative;
  top: -16px;
  right: -48px;
  overflow: hidden;
}

@media only screen and (max-width: 48em) {
  .surf-illustration {
      margin-top: 2rem;
      right: auto;
  }
}

.caption {
  font-size: 0.8rem;
  margin-left: 3rem;
}

@media only screen and (max-width: 48em) {
  .caption {
    margin-left: auto;
    padding: 0 1rem;
  }
}

.van-illustration {
  position: relative;
  top: 8rem;
  margin-bottom: -1rem;
  // right: -48px;
}

.lighthouse {
  margin: 2rem 0 1rem;
}
