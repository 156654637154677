.main {
  position: relative;
  display: block;
  max-width: 100%;
  margin: 3.2rem 0 0;
}

.intro {
  margin-top: 2rem;
}

@media only screen and (max-width: 48em) {
  .intro {
    margin-top: 0;
  }
}

.view-work {
  position: relative;
}

.view-work::after {
  content: '';
  position: absolute;
  top: 0.7rem;
  right: -1.6rem;
  width: 1rem;
  height: 24px;
  background: url(../img/sprite.svg) -0px -0px no-repeat;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  transform: translateY(-50%);
}

.case-study {
  margin-top: 4rem;
  ul {
    font-size: 2.8rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    border-bottom: 1px dashed #2b2b2b;
    padding: 2rem 0;
    position: relative;
    display: block;
  }
  li:first-child {
    border-top: 1px dashed #2b2b2b;
  }
  li:after {
    content: '';
    position: absolute;
    margin: 2rem 0;
    // top: 50%;
    right: 4px;
    width: 24px;
    height: 24px;
    background: url(../img/sprite.svg) -26px -0px no-repeat;
    // transform: translateY(-50%);
    transition: all 0.1s ease;
  }
  li:hover:after {
    right: 0px;
  }
}

@media only screen and (max-width: 48em) {
  .case-study {
    margin-top: 2rem;
  }
  .case-study ul {
      font-size: 1.5rem;
  }
  .case-study li:after {
    content: '';
    position: absolute;
    margin: 1rem 0;
    right: 4px;
    width: 24px;
    height: 24px;
    background: url(../img/sprite.svg) -26px -0px no-repeat;
    transition: all 0.1s ease;
  }
}

@media only screen and (max-width: 25em) {
  .case-study {
    margin-top: 2rem;
  }
  .case-study ul {
      font-size: 1.1rem;
  }
  .case-study li:after {
    content: '';
    position: absolute;
    margin: .6rem 0;
    // top: 50%;
    right: 4px;
    width: 24px;
    height: 24px;
    background: url(../img/sprite.svg) -26px -0px no-repeat;
    // transform: translateY(-50%);
    transition: all 0.1s ease;
  }
}

.client-logo {
  margin-left: 4.2rem;
}

.client-logo::before {
  content: '';
  position: absolute;
  margin: 3rem 0;
  left: 0;
  width: 48px;
  height: 48px;
  background: url(../img/sprite.svg) -0px -32px no-repeat;
  transform: translateY(-50%);
}

.black-gate-logo.client-logo::before {
  background: url(../img/sprite.svg) -0px -32px no-repeat;
}

.katalane-logo.client-logo::before {
  background: url(../img/sprite.svg) -0px -83px no-repeat;
}

.studio-logo.client-logo::before {
  background: url(../img/sprite.svg) -0px -134px no-repeat;
}

@media only screen and (max-width: 48em) {
  .client-logo::before {
    content: '';
    position: absolute;
    margin: 1.5rem 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: url(../img/sprite.svg) -0px -32px no-repeat;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 25em) {
  .client-logo::before {
    content: '';
    position: absolute;
    margin: 1.2rem 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: url(../img/sprite.svg) -0px -32px no-repeat;
    transform: translateY(-50%);
  }
}
