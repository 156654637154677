.h-wrapper {
  height: 100vh;
  position: relative;
}

.h-wrapper-50 {
  height: 60vh;
  position: relative;
}

.parent {
  // position: relative;
  // height: 50vh;
}

.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.header {
  padding: 16px;
}
.logo {
  padding: 12px 0;
  margin: 0;
}

@media screen and (max-width: 780px) { /* Specific to this particular image */
  .h-wrapper-50 {
    height: 100vh;
    position: relative;
  }
 .logo {
   position: absolute;
   padding: 1.2rem 0;
   left: 50%;
   transform: translateX(-66px);
 }
 .header {
   padding: 0;
 }
}
