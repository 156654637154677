// fonts
$font-family: 'Roboto Mono', monospace;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$font-height: 1.5;

// colors
$blue: #5385C2;
$red: #CC7356;
$teal: #93BFA6;
$sandy: #BFAA95;
$grey: #8899A8;
$navy: #3B3E44;
$black: #2b2b2b;
$paragraph-text: #809CEC;

// page colours
.home { background: $blue }
.about { background: $grey }
.site { background: $navy }
.black-gate { background: $red }
.katalane { background: $teal }
.black-gate-studio { background: $sandy }


* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.95rem;
  color: $black;
  transition: all .4s .3s ease-in;
  overflow-x: hidden;
}

body {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
