.nav {
  font-size: 1rem;
  ul {
    list-style: none;
    margin: 18px 0 0;
    padding: 0;
    text-align: center;

    li {
      display: inline-flex;
      margin: 2px 10px;
      // a {
      //   color: #E9CD98;
      // }
    }
  }
  a {
    text-decoration: none;
    position: relative;
    padding-bottom: 1em;
    overflow: hidden;
    color: white;
    &:hover {
      color: white;
    }
    &:hover:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 4px;
      white-space: nowrap;
      content: attr(data-text)attr(data-text);
      color: transparent;
      line-height: 2;
      text-decoration: underline;
      text-decoration-style: wavy;
      text-decoration-color: #fff;
    }
    &:hover:after {
      animation: wavy-slide 3s linear infinite;
    }
  }

  @keyframes wavy-slide {
    to {
      margin-left: -50.5%;
    }
  }
}
nav a.active:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 4px;
    white-space: nowrap;
    content: attr(data-text)attr(data-text);
    color: transparent;
    line-height: 2;
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-color: #fff;
  }
