.footer {
  margin: 8rem 0 0;
  padding: 1rem 0 6rem;
  // background: white;

  h3 {
    font-size: 1.2rem;
    font-family: 'Canela Web', serif;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  h3::after {
    display: none;
  }

  ul {
    padding: 0;
    // margin: 0;
    list-style: none;
    font-size: .95rem;
  }
  li {
    line-height: 1.8;
  }
  a {
    padding-bottom: .5rem;
  }
}

@media only screen and (max-width: 48em) {
  .footer {
    margin: 4rem 0 0;
    padding: 1rem 0 4rem;
  }
}

.logo-footer {
  padding-top: 1.5rem;
}

.waves {
  margin-bottom: 5rem;
}
// .footer:before {
//   content: '';
//   position: absolute;
//   background: url(../img/waves.svg) 0 0 no-repeat;
//   height: 240px;
//   width: 1138px;
//   margin-top: -18rem;
//   // margin-left: 50%;
//   // transform: translateX(-50%);
//   overflow: hidden;
// }
