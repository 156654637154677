.hero {
  opacity: 1;
  margin-top: 3.2rem;
  // z-index: 100;
}

.hero::after {
  content: '';
  position: absolute;
  margin-top: -1.4rem;
  right: -4rem;
  width: 560px;
  height: 370px;
  background: url(../img/waves-hero.svg) -0px -0px no-repeat;
  transform: translateY(-50%);
  z-index: -100;
}

@media only screen and (max-width: 64em) {
  .hero {
    opacity: 1;
    margin-top: 8.2rem;
    // z-index: 100;
  }
  .hero::after {
    content: '';
    position: absolute;
    margin-top: -0.9rem;
    right: 0;
    width: 280px;
    height: 97px;
    background: url(../img/waves-hero.svg) -0px -0px no-repeat;
    // transform: translateY(-50%);
    z-index: -100;
    overflow: hidden;
  }
}

.my-stats {
  margin-top: 5rem
}

.client-intro {
  margin: 4rem 0 2rem;
}

.client-intro p {
  text-align: center;
  margin: 0 auto 6rem;
  max-width: 712px;
}

.client-intro h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 4rem;
}

.client-intro h2::after {
  content: '';
  position: absolute;
  margin: 5rem 0;
  left: 50%;
  width: 90px;
  height: 16px;
  background: url(../img/sprite.svg) -0px -188px no-repeat;
  transform: translatex(-50%);
}

.step {
  margin-top: 8rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 64em) {
  .step {
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
}

.step p {
  margin: 3rem 0;
}

.step h3 {
  font-size: 2.2rem;
  letter-spacing: 1px;
  position:relative;
  margin-bottom: 4rem;
}

.step h3::after {
  content: '';
  position: absolute;
  margin: 4.5rem 0;
  left: 0;
  width: 90px;
  height: 16px;
  background: url(../img/sprite.svg) -0px -188px no-repeat;
  transform: translateY(-50%);
}
